body {
  padding-top: 4.8em;
}

nav {
  box-shadow: 0px 21.213px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: $navbar-bg-color;

  .logo {
    height: 2.8em;
    width: auto;
  }
}
