p,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
}

.page-loading {
  min-height: calc(100vh - 4.8em); // substract 4em for the navbar
}

pre {
  margin: 0 !important;
}

.illustration {
  height: 12em;
  width: auto;
}

.bst-hero-img {
  background-size: cover, 10em;
  background-repeat: no-repeat;
  background-position: center center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $img-overlay-color;
    opacity: $img-overlay-opacity;
    z-index: 1;
    transition: all 0.5s ease-out;

    &:hover {
      opacity: 0;
    }
  }
}

.bst-hero-txt-left {
  width: 100%;
  padding: 15% 40% 15% 10%; //25%

  @include mobile-and-tablet-only {
    padding: 10%;
  }
}

.bst-hero-txt-right {
  width: 100%;
  padding: 15% 10% 15% 40%;

  @include mobile-and-tablet-only {
    padding: 10%;
  }
}

.btn-autosize {
  @include large-devices-only {
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
    border-radius: 0.3rem !important;
  }
}
