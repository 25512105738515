// Font family

//$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base:       $font-family-sans-serif;
//url('Ubuntu-RI-webfont.ttf') format('truetype'),
@font-face {
  font-family: Montserrat;
  src: url(../../assets/font/Montserrat-Medium.ttf) format("truetype");
}
$headings-font-family: "Montserrat", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-weight: 600;
/* 
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem; */

$enable-responsive-font-sizes: true;

// Color variations

$white: #fff;
$offwhite: #f7f7f7;
$gray-100: $offwhite;
$black: #222;

$primary: #23c4b6;
$secondary: $black;

$body-bg: $white;
$body-color: $black; // text color

$h-line-color: lightgrey;

// for navbar dropdown menu
$dropdown-link-active-bg: darken($offwhite, 10%);

// Navbar
$navbar-bg-color: $offwhite;
$navbar-light-color: lighten($black, 30%);
$navbar-light-hover-color: $black;
$navbar-light-active-color: $black;

// Image overlay
$img-overlay-color: $black;
$img-overlay-opacity: 0.65;

// Sections
$section-primary-color: $body-color;
$section-secondary-color: $offwhite;

// Footer
$footer-bg-color: $secondary;
$footer-font-color: $offwhite;
